<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("sideMenu.events") }}</h3>
    <event-filters class="mb-2" />
    <b-row no-gutters class="justify-content-end">
      <b-button
        v-if="!isDomiUser"
        variant="secondary"
        size="sm"
        dense
        class="mb-4"
        @click="goToEvent"
        >{{ $t("page.event.new-event") }}</b-button
      >
    </b-row>
    <infinite-scroll
      @load="retrieveEvents"
      :canScroll="formatedEvents >= limit"
    >
      <event-table
        :events="formatedEvents"
        :fields="fields"
        :actions="actions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        no-local-sorting
      />
    </infinite-scroll>
  </div>
</template>

<script>
import { userMixin, appMixin } from "./../mixins";

import EventFilters from "./../components/Event/EventFilters";
import EventTable from "./../components/Event/EventTable";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";
import { pagination } from "../constants/pagination";

export default {
  components: {
    EventFilters,
    EventTable,
    InfiniteScroll
  },
  mixins: [userMixin, appMixin],
  async mounted() {
    this.retrieveEvents(true);
  },
  data() {
    return {
      limit: pagination.limit,
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.goToEventDetails
        }
      ]
    };
  },
  computed: {
    isDomiUser() {
      return this.$store.getters["auth/isDomiUser"];
    },
    fields() {
      const initFields = [
        { key: "createdAt", label: "date", sort: true },
        { key: "title", label: "page.event.title", sort: false },
        { key: "photos", label: "photos", sort: false },
        { key: "hasDocuments", label: "file", sort: false },
        { key: "commentsNumber", label: "commentsShort", sort: false },
        { key: "likesNumber", label: "L", sort: false },
        { key: "viewsNumber", label: "V", sort: false },

        { key: "group", label: "page.event.group", sort: false },
        { key: "eventDate", label: "page.event.eventDate", sort: true },
        {
          key: "participantsNum",
          label: "page.event.participants",
          sort: false,
          center: true
        },
        {
          key: "registeredNum",
          label: "page.event.registered",
          sort: false,
          center: true
        },
        { key: "actions", label: "" }
      ];
      return initFields;
    },
    events() {
      return this.$store.getters["event/getEvents"];
    },
    formatedEvents() {
      return this.events.map(item => {
        return {
          id: item._id,
          createdAt: item.createdAt,
          body: item.body,
          photo: item.photo,
          photos: item.photos,
          group: this.$is1Board && item.groups ? item.groups[0] : null,
          commentsNumber: item.comments?.length || 0,
          likesNumber: item.likes?.length || 0,
          viewsNumber: item.viewedBy?.length || 0,
          hasDocuments: item.documents && item.documents.length > 0,
          title: item.title,
          participantsNum: item.eventId.participantsNum,
          registeredNum: item.eventId.registeredNum,
          eventDate: item.eventId.date,
          eventId: item.eventId._id,
          condominiumId: item.condominiumId
        };
      });
    },
    sortBy: {
      get() {
        return this.$store.getters["event/getSortBy"];
      },
      set(value) {
        this.$store.commit("event/SET_SORT_BY", value);
        this.retrieveEvents(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["event/getSortDesc"];
      },
      set(value) {
        this.$store.commit("event/SET_SORT_DESC", value);
        this.retrieveEvents(true);
      }
    }
  },
  methods: {
    getRoleInCondominium(condominiumId, owner) {
      return (
        owner.condominiums.find(c => {
          return c.condominiumId === condominiumId;
        })?.role || "-"
      );
    },
    goToEventDetails(postId, condominiumId) {
      this.$router.push({
        name: "EventDetails",
        params: { postId, condominiumId }
      });
    },
    openDeleteConfirmationModal(postId, condominiumId) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.deletePost,
        confirmBtnParams: { postId, condominiumId },
        type: "DELETE"
      });
    },
    async retrieveEvents(initial) {
      return this.$store.dispatch("event/retrieveEvents", {
        initial,
        hasPagination: true
      });
    },
    goToEvent() {
      this.$router.push({
        name: "CreateEvent"
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
