import { render, staticRenderFns } from "./EventFilters.vue?vue&type=template&id=b1b7a204&scoped=true&"
import script from "./EventFilters.vue?vue&type=script&lang=js&"
export * from "./EventFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1b7a204",
  null
  
)

export default component.exports